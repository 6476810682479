/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Link } from 'gatsby';
import styles from './styles.module.css';
import tooltipSvg from './img/tooltip.svg';
import PRICING_ACTIONS from '../../../../../redux/actions/Pricing';
import QuestionOptionInfo from './questionOptionInfo';

const QuestionOption = ({
  to,
  imgSrc,
  text,
  info,
  // priceText,
  questionId,
  answerId,
}) => {
  // State
  const [isInfoOpen, setInfoOpen] = React.useState(false);
  // Hooks
  const dispatch = useDispatch();
  // Events
  const handleClick = () => {
    const setAnswer = PRICING_ACTIONS.setAnswer(questionId, answerId);
    dispatch(setAnswer);
  };

  return (
    <>

      <div className={clsx(styles.container)}>
        {!!imgSrc && (
        <div className={styles.imageHolder}>
          <img src={imgSrc} alt="" className={styles.img} />
        </div>
        )}
        <div className={styles.textHolder} onClick={() => setInfoOpen(true)}>
          <h6>{text}</h6>
          <span>
            <img
              src={tooltipSvg}
              alt="Question mark icon."
              className={styles.tooltip}
            />
          </span>
        </div>
        <div className={styles.btnHolder}>
          <Link to={to}>
            <button
              type="button"
              className={styles.button}
              onClick={handleClick}
            >
              <span className={styles.next}>Select &#10003;</span>
            </button>
          </Link>
        </div>
      </div>
      <QuestionOptionInfo
        isOpen={isInfoOpen}
        iconSrc={imgSrc}
        heading={text}
        onClose={() => setInfoOpen(false)}
      >
        {info}
      </QuestionOptionInfo>
    </>
  );
};

QuestionOption.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  info: PropTypes.node.isRequired,
  // priceText: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  answerId: PropTypes.string.isRequired,
};

export default QuestionOption;
