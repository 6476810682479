import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import QuestionText from '../../../../components/pricing/question/questionText';
import QuestionOptionGroup from '../../../../components/pricing/question/questionOptionGroup';
import GoBack from '../../../../components/pricing/goBack';
import QuestionBar from '../../../../components/pricing/question/questionBar';

const ObjectiveQuestion = ({ index, question, prevQuestion }) => {
  return (
    <>
      <QuestionBar index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className='section__h1'>Your Objectives</h4>
        <p className='section__p'>
          Our proposal can be tailored to meet your specific goals. There are three classic goals
          that can be traded off against each other. We can increase consideration for one
          by reducing consideration for another.
        </p>
        <p className='section__p'>
          For example : to reduce cost we can deliver over a longer timeline
          and compromise on certain aspects of quality such as design while still keeping the app
           bug free and very usable. Increasing quality will require more time spent on
          making the app work better and hence it will increase cost or time to delivery or both.
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading={'What is most important to you?'}
      >
        <p className='section__p'>
          This represents your primary consideration while building your app.
          <br />
          Please select ONE option.
        </p>
      </QuestionText>

      <QuestionOptionGroup
        nextQuestion={'testimonials2'}
        question={question}
        options={[
          {
            // imgSrc: questionSvg,
            text: 'High Quality',
            questionId: question.id,
            answerId: question.answerIds.Quality,
          },
          {
            // imgSrc: textSvg,
            text: 'Low Price',
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.Price,
          },
          {
            // imgSrc: textSvg,
            text: 'Fast Delivery',
            questionId: question.id,
            answerId: question.answerIds.Speed,
          },
        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};

ObjectiveQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

ObjectiveQuestion.defaultProps = {
  prevQuestion: null,
};

export default ObjectiveQuestion;
