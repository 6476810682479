import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import EstimateBar from '../../../../components/pricing/estimate/estimateBar';
import QuestionNumber from '../../../../components/pricing/question/questionNumber';
import QuestionText from '../../../../components/pricing/question/questionText';
import QuestionOptionGroup from '../../../../components/pricing/question/questionOptionGroup';
import GoBack from '../../../../components/pricing/goBack';
import { useDispatch, useSelector } from 'react-redux';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import PlatformOptions from '../platforms/PlatformOptions';

const FundingQuestion = ({ index, question, prevQuestion }) => {
  const Pricing = useSelector((state) => state.Pricing);
  const [myData, setMyData] = useState({
    none: false,
    grant: false,
    fund: false,
  });
  const dispatch = useDispatch();
  const { answers } = Pricing;
  const funding =
    typeof answers.get('funding') === 'object' ? answers.get('funding') : {};
  const answersComp = {
    grant: false,
    fund: false,
    none: false,
    ...funding,
  };
  const handleChange = (data) => {
    const newData = { ...data };
    if (!myData.none && data.none) {
      newData.grant = false;
      newData.fund = false;
    }
    if (newData.grant || newData.fund) {
      newData.none = false;
    }
    setMyData({ ...newData });
    const setAnswer = PRICING_ACTIONS.setAnswer(question.id, newData);
    dispatch(setAnswer);
  };

  return (
    <>
      <EstimateBar />
      <QuestionNumber index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className='section__h1'>Funding</h4>
        <p className='section__p'>
          Funding will improve your company valuation because it speeds up growth<br/>
          by unlocking resources such as developers and ads.</p>
        <p className='section__p'>
          Funding also gets you trust with partners, customers and employees
          that you are here to stay.
        </p>
        <p className='section__p'>
          OpZoom has helped raise up to <b>$6 million</b> for startups.
        </p>
        <p className='section__p'>
          Funding may be available if you apply.<br/>
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading='Which of these funding options do you want?'
      >
        <p className='section__p'>Please select all that you want</p>
      </QuestionText>
      <PlatformOptions
        answers={answersComp}
        setAnswers={handleChange}
        platforms={[
          {
            text: (
              <>
                <strong>OpZoom Grant:</strong> Provides Up to&nbsp;
                <strong>$1,000&nbsp;</strong>
                in matching funds to fund design, market
                research and a plan for success. This will qualify you for the
                OpZoom Fund.
              </>
            ),
            questionId: question.id,
            answerId: question.answerIds.GRANT,
          },
          {
            text: (
              <>
                <strong>OpZoom Fund:</strong> Invests Up to&nbsp;
                <strong>$100,000&nbsp;</strong>
                in custom software in matching funds.
              </>
            ),
            questionId: question.id,
            answerId: question.answerIds.FUND,
          },
          {
            text: <>None of the above.</>,
            questionId: question.id,
            answerId: question.answerIds.NONE,
          },
        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};

FundingQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};
FundingQuestion.defaultProps = {
  prevQuestion: null,
};

export default FundingQuestion;
