import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import QuestionText from "../../../../components/pricing/question/questionText";
import GoBack from "../../../../components/pricing/goBack";
import GreetingForm from "../../../../components/pricing/greeting/greetingForm";
import useClidParser from "../../../../hooks/pricing/useClidParser";
import checkLinkCount from "../../../../functions/pricing/checkLinkCount";
import PRICING_ACTIONS from "../../../../redux/actions/Pricing";
import Swal from "sweetalert2";

const SignupGreeting = ({ index, question, prevQuestion }) => {

  const Pricing = useSelector((state) => state.Pricing);
  const { email, linkCount, charge, initialEstimate , devSteps } = Pricing;
  const dispatch = useDispatch();
  useClidParser();

  useEffect(() => {
    const setAnswer = PRICING_ACTIONS.setAnswer('signupGreeting', 'updated');
    dispatch(setAnswer);
    console.log("hook")
    checkLinkCount(linkCount, charge, initialEstimate , devSteps);
  }, []);

  return (
    <>
      <br />
      <br />
      <br />

      <QuestionText
        index={"Please click on the link sent to your email to continue"}
      >
        <p className="section__p">
          If you don’t see the link in a few seconds, please go back.
        </p>
      </QuestionText>
      <GoBack prevQuestionId='sign-up' />
    </>
  );
};

SignupGreeting.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

SignupGreeting.defaultProps = {
  prevQuestion: null,
};

export default SignupGreeting;
