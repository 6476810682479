import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionText from '../../../../components/pricing/question/questionText';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import styles from './styles.module.css';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import PlatformOptions from '../platforms/PlatformOptions';
import QuestionBar from '../../../../components/pricing/question/questionBar';
import GoBack from '../../../../components/pricing/goBack';

const PropertiesQuestion = ({ index, question, prevQuestion }) => {
  // Hooks
  const dispatch = useDispatch();
  const Pricing = useSelector((state) => state.Pricing);
  const { answers } = Pricing;
  const properties = typeof answers.get('properties') === 'object'
    ? answers.get('properties')
    : {};
  const answersComp = {
    scalability: false,
    security: false,
    userfriendly: false,
    other: '',
    ...properties,
  };

  // Events
  const handleChange = (data) => {
    const setAnswer = PRICING_ACTIONS.setAnswer(question.id, data);
    dispatch(setAnswer);
  };
  return (
    <>
      <QuestionBar index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className="section__h1">Properties of Your App.</h4>
        <p className="section__p">
          Depending on why you're building this app, your most important concern
          may vary.
        </p>
        <p className="section__p">
          Scalability is an important goal if you expect a lot of users quickly.
          Security is important if we need to take special care of user data
          such as medical records or financial records. User Experience is the
          most important if you are working in a highly competitive field for
          user attention such as social media where a lot of competing apps
          already exist.
        </p>
        <p className="section__p">
          We will use your answer to pick the right combination of resources for
          the app.
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading="What properties of the app are important to you?"
      >
        <p className="section__p">
          This represents the aspects you care for in your app.
          <br />
          Please pick at least one option to proceed.
        </p>
      </QuestionText>

      <PlatformOptions
        answers={answersComp}
        setAnswers={handleChange}
        platforms={[
          {
            text: 'Scalability',
            questionId: question.id,
            answerId: question.answerIds.Scalability,
          },
          {
            text: 'Security',
            questionId: question.id,
            answerId: question.answerIds.Security,
          },
          {
            text: 'User Friendliness',
            questionId: question.id,
            answerId: question.answerIds.UserFriendly,
          },
        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};
export default PropertiesQuestion;
