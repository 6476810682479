import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import QuestionText from '../../../../components/pricing/question/questionText';
import GoBack from '../../../../components/pricing/goBack';
import PlatformOptions from '../platforms/PlatformOptions';

import findFirstNullQuestion from '../../../../functions/pricing/findFirstNullQuestion';
import calcFirstlandingPage from '../../../../functions/pricing/calcFirstlandingPage';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import QuestionBar from '../../../../components/pricing/question/questionBar';

const FeatureQuestion = ({ index, question, prevQuestion }) => {
  const Pricing = useSelector((state) => state.Pricing);
  const [myData, setMyData] = useState({
    none: false,
    ar: false,
    gaming: false,
    e_commerce: false,
    apis: false,
    streaming: false,
    payments: false,
    crypto: false,
    ads: false,
    crm: false,
    artificial_inteligence: false,
    messaging: false,
    comments: false,
    other: '',
  });
  const { answers, verifyCount, charge } = Pricing;
  const dispatch = useDispatch();
  const features = typeof answers.get('features') === 'object' ? answers.get('features') : {};
  const answersComp = {
    none: false,
    ar: false,
    gaming: false,
    e_commerce: false,
    apis: false,
    streaming: false,
    payments: false,
    crypto: false,
    ads: false,
    crm: false,
    artificial_inteligence: false,
    messaging: false,
    comments: false,
    other: '',
    ...features,
  };
  const handleChange = (data) => {
    const newData = { ...data };
    if (!myData.none && data.none) {
      newData.ar = false;
      newData.gaming = false;
      newData.e_commerce = false;
      newData.apis = false;
      newData.streaming = false;
      newData.payments = false;
      newData.crypto = false;
      newData.ads = false;
      newData.crm = false;
      newData.artificial_inteligence = false;
      newData.messaging = false;
      newData.comments = false;
      newData.other = '';
    }
    if (
      newData.ar
      || newData.gaming
      || newData.e_commerce
      || newData.apis
      || newData.streaming
      || newData.payments
      || newData.crypto
      || newData.ads
      || newData.crm
      || newData.comments
      || newData.messaging
      || newData.artificial_inteligence
      || newData.other.length
    ) {
      newData.none = false;
    }
    setMyData({ ...newData });
    const setAnswer = PRICING_ACTIONS.setAnswer(question.id, newData);
    dispatch(setAnswer);
  };
  return (
    <>
      <QuestionBar index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className="section__h1">Additional Features</h4>
        <p className="section__p">
          Your success depends on having access to cutting edge features.
          <br />
          Your app can be customized to include all the features you need.
          <br />
          Here are some of the features that are currently in high demand.
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading="Which of these features do you need?"
      >
        <p className="section__p">Select an option.</p>
      </QuestionText>
      <PlatformOptions
        answers={answersComp}
        setAnswers={handleChange}
        platforms={[
          {
            text: <>No Additional Features</>,
            questionId: question.id,
            answerId: question.answerIds.NONE,
          },
          {
            text: <>AR/ VR/ Metaverse</>,
            questionId: question.id,
            answerId: question.answerIds.AR,
          },
          {
            text: <> Gaming/ Unity</>,
            questionId: question.id,
            answerId: question.answerIds.GAMING,
          },
          {
            text: <> Ad platform integrations</>,
            questionId: question.id,
            answerId: question.answerIds.ADS,
          },
          {
            text: <> Video, multimedia, streaming</>,
            questionId: question.id,
            answerId: question.answerIds.STREAMING,
          },
          {
            text: <> CRM integration such as SalesForce, Microsoft Dynamics</>,
            questionId: question.id,
            answerId: question.answerIds.CRM,
          },
          {
            text: <> E commerce: Shopify / BigCommerce</>,
            questionId: question.id,
            answerId: question.answerIds.E_COMMERCE,
          },
          {
            text: <> Any other APIs</>,
            questionId: question.id,
            answerId: question.answerIds.APIS,
          },
          {
            text: <> Payments (Credit Cards / Debit / ACH)</>,
            questionId: question.id,
            answerId: question.answerIds.PAYMENTS,
          },
          {
            text: <> Blockchain / Crypto payments / NFTs</>,
            questionId: question.id,
            answerId: question.answerIds.CRYPTO,
          },
          {
            text: <>Comments</>,
            questionId: question.id,
            answerId: question.answerIds.COMMENTS,
          },
          {
            text: <> Messaging</>,
            questionId: question.id,
            answerId: question.answerIds.MESSAGING,
          },
          {
            text: <>Artificial Intelligence</>,
            questionId: question.id,
            answerId: question.answerIds.AI,
          },
        ]}
      />

      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};

FeatureQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

FeatureQuestion.defaultProps = {
  prevQuestion: null,
};

export default FeatureQuestion;
