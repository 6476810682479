import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import QuestionText from '../../../../components/pricing/question/questionText';
import QuestionOptionGroup from '../../../../components/pricing/question/questionOptionGroup';
import GoBack from '../../../../components/pricing/goBack';
import QuestionBar from '../../../../components/pricing/question/questionBar';

const RevenueRangeQuestion = ({ index, question, prevQuestion }) => (
  <>
    <QuestionBar index={index + 1} count={QUESTIONS.length} />
    <div className={classConcat('container', styles.container)}>
      <h4 className="section__h1">Marketing and Operations</h4>
      <p className="section__p">
        Once an app is built, it takes work to operate and market it.
      </p>
      <p className="section__p">
        To help you decide on the features that are reasonable at this stage,
        we need an idea of what you're planning to invest to find users and
        service them once the app is built.
      </p>
      <p className="section__p">
        In other words, if this app were built at ZERO cost to you,
        <br />
        what can you invest to market and operate this app
        <br />
        while we wait for revenue to build up?
      </p>
    </div>
    <QuestionText
      index={index + 1}
      heading="What can you invest to operate and market this app?"
    >
      <p className="section__p">
        This represents your confidence in your idea.
        <br />
        All choices are perfectly OK !
        <br />
        Even if you feel its too early to invest,...
        <br />
        ... we can help with
        {' '}
        <b>FREE</b>
        {' '}
        data and research.
        <br />
        Please select ONE option.
      </p>
    </QuestionText>

    <QuestionOptionGroup
      nextQuestion="objective"
      question={question}
      options={[
        {
          // imgSrc: questionSvg,
          text: 'Under $1,000',
          priceText: '$$$',
          questionId: question.id,
          answerId: question.answerIds.u1k,
        },
        {
          // imgSrc: textSvg,
          text: 'Between $1,000 and $10 thousand',
          priceText: '$$$',
          questionId: question.id,
          answerId: question.answerIds.u10k,
        },
        {
          // imgSrc: textSvg,
          text: 'Between $10 thousand and $100 thousand',
          priceText: '$$$',
          questionId: question.id,
          answerId: question.answerIds.u100k,
        },
        {
          // imgSrc: textSvg,
          text: 'Over $100 thousand',
          priceText: '$$$',
          questionId: question.id,
          answerId: question.answerIds.a100k,
        },
      ]}
    />
    <GoBack prevQuestionId={prevQuestion.id} />
  </>
);

RevenueRangeQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

RevenueRangeQuestion.defaultProps = {
  prevQuestion: null,
};

export default RevenueRangeQuestion;
