import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.css';
import PRICING_ACTIONS from '../../../../redux/actions/Pricing';
import PlatformOptions from '../platforms/PlatformOptions';
import QuestionText from '../../../../components/pricing/question/questionText';
import GoBack from '../../../../components/pricing/goBack';
import QUESTIONS from '../../../../constants/pricing/questions';

const RevenueModel = ({ question, index, prevQuestion }) => {
  const Pricing = useSelector((state) => state.Pricing);

  const { answers } = Pricing;
  const dispatch = useDispatch();
  const revenueModal = typeof answers.get('revenue_model') === 'object' ? answers.get('revenue_model') : {};

  const answersComp = {
    advertisements: false,
    monthly_or_annual_fees: false,
    profit_on_sales: false,
    other: '',
    ...revenueModal,
  };
  const handleChange = (data) => {
    const setAnswer = PRICING_ACTIONS.setAnswer(question.id, data);
    dispatch(setAnswer);
  };
  return (
    <>
      <div className={clsx('container', styles.container)}>
        <h4 className="section__h1">Your Revenue Model</h4>
        <p className="section__p">
          To get a return on your investment in the app you are expecting to earn revenue.
          The most common revenue options listed below.
          Please pick the ones you plan to use or describe your revenue model in the Other option.
        </p>
      </div>
      <QuestionText
        index={index + 1}
        count={QUESTIONS.length}
        heading="How will your app make money ?"
      >
        <p className="section__p">
          Select
          {' '}
          <b>ALL</b>
          {' '}
          relevant Options.
        </p>
      </QuestionText>
      <PlatformOptions
        answers={answersComp}
        setAnswers={handleChange}
        platforms={[
          {
            text: <>Advertisements</>,
            questionId: question.id,
            answerId: question.answerIds.Advertisements,
          },
          {
            text: <> Monthly or Annual fees</>,
            questionId: question.id,
            answerId: question.answerIds.Monthly_or_Annual_fees,
          },
          {
            text: <> Profit on Sales</>,
            questionId: question.id,
            answerId: question.answerIds.Profit_on_Sales,
          },

        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />

    </>
  );
};

export default RevenueModel;
