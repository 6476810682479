import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import EstimateBar from '../../../../components/pricing/estimate/estimateBar';
import QuestionNumber from '../../../../components/pricing/question/questionNumber';
import QuestionText from '../../../../components/pricing/question/questionText';
import QuestionOptionGroup from '../../../../components/pricing/question/questionOptionGroup';
import GoBack from '../../../../components/pricing/goBack';

import { useSelector, useDispatch } from 'react-redux';

const RevenueQuestion = ({ index, question, prevQuestion }) => {
  const Pricing = useSelector((state) => state.Pricing);
  const { answers, verifyCount, charge } = Pricing;
  console.log({ answrs: answers?.get('revenue'), Pricing });

  return (
    <>
      <EstimateBar />
      <QuestionNumber index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className='section__h1'>Revenue Expectation</h4>
        <p className='section__p'>
          <p>
            To attract resources to this project, we need an idea of the revenue
            potential you see.
          </p>
          <p>
            Your answer will help us understand the potential "bang for buck"
            for you and contributors to this project.
          </p>
          <p>
            This will also help us get you the information you need to decide
            upon the features you could invest in at this stage.
          </p>
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading='What do you expect your annual revenue of this product will be in 1 year
        when you succeed?'
      >
        <p className='section__p'>Please select one option.</p>
      </QuestionText>
      <QuestionOptionGroup
        // nextQuestion={'marketing'}
        // nextQuestion={findFirstNullQuestion(answers) === undefined ? calcFirstlandingPage(charge,verifyCount) : 'marketing'}
        nextQuestion={'testimonials2'}
        question={question}
        options={[
          {
            // imgSrc: questionSvg,
            text: 'Between $10,000 - $100,000',
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.u100k,
          },
          {
            // imgSrc: textSvg,
            text: 'Between $100,000 - $1 million',
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.u1m,
          },
          {
            // imgSrc: textSvg,
            text: 'Between $1 million - $10 million',
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.u10m,
          },
          {
            // imgSrc: textSvg,
            text: 'Over $10 million',
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.a10m,
          },
        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};

RevenueQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

RevenueQuestion.defaultProps = {
  prevQuestion: null,
};

export default RevenueQuestion;
