// import { useLocation } from "@reach/router";
// import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { navigate } from "gatsby";

const checkLinkCount = (linkCount, charge, initialEstimate, devSteps) => {
  // const Pricing = useSelector((state) => state.Pricing);
  // if (linkCount > 0 && inDev === false) {
  //   const to = "/cost-to-make-an-app/design";
  //   navigate(to);
  //   return;
  // }
  if (initialEstimate > 0) {
    const to = "/cost-to-make-an-app/dev-steps";
    navigate(to);
    return;
  } else if (devSteps > 0) {
    const to = "/cost-to-make-an-app/expert-consult";
    navigate(to);
    return;
  } else if (charge) {
    const to = "/cost-to-make-an-app/thank-you";
    navigate(to);
    return;
  } else if (linkCount > 0) {
    const to = "/cost-to-make-an-app/estimation";
    // const to="/"
    navigate(to);
    return;
  }
};

export default checkLinkCount;
