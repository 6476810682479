import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import styles from './styles.module.css';
import classConcat from '../../../../util/ClassConcat';
import QUESTIONS from '../../../../constants/pricing/questions';
import QuestionText from '../../../../components/pricing/question/questionText';
import QuestionOptionGroup from '../../../../components/pricing/question/questionOptionGroup';
import GoBack from '../../../../components/pricing/goBack';

import { useSelector, useDispatch } from 'react-redux';
import QuestionBar from '../../../../components/pricing/question/questionBar';

const MarketingQuestion = ({ index, question, prevQuestion }) => {
  const Pricing = useSelector((state) => state.Pricing);
  const { answers, verifyCount, charge } = Pricing;

  return (
    <>
      <QuestionBar index={index + 1} count={QUESTIONS.length} />
      <div className={classConcat('container', styles.container)}>
        <h4 className='section__h1'>Strategy</h4>
        <p className='section__p'>
          Once the app is developed, you need a strategy to succeed.
          <br />
        </p>
        <p className='section__p'>
          You want to know the most common path to success for apps. The path to
          users and earning revenue.
          <br />
        </p>
        <p className='section__p'>
          The most frequently asked questions include:
          <ul>
            <li>What should you build first and what can wait?</li>
            <li>What are the common ways that apps are marketed?</li>
            <li>What do you need to attract investors?</li>
          </ul>
        </p>
        <p className='section__p'>
          If you share these concerns and questions with 1000's of entrepreneurs
          we see,
          <br />
          then our <b>strategy blueprint</b> is the perfect solution for you. It
          covers the:
          <br />
          <ul>
            <li>Minimum investment risk path to success, and</li>
            <li>
              Step-by-step guide to generate unstoppable demand for your app.
            </li>
            <li>Ultimate guide to hooking investors.</li>
          </ul>
        </p>
      </div>
      <QuestionText
        index={index + 1}
        heading='Do you want the app strategy blueprint?'
      >
        <p className='section__p'>Please select an option.</p>
      </QuestionText>
      <QuestionOptionGroup
        // nextQuestion={
        //   findFirstNullQuestion(answers) === undefined
        //     ? calcFirstlandingPage(charge, verifyCount)
        //     : "sign-up"
        // }
        nextQuestion='value'
        question={question}
        options={[
          {
            // imgSrc: questionSvg,
            text: 'YES',
            info: <>YES</>,
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.YES,
          },
          {
            // imgSrc: textSvg,
            text: 'NO',
            info: <>NO</>,
            priceText: '$$$',
            questionId: question.id,
            answerId: question.answerIds.NO,
          },
        ]}
      />
      <GoBack prevQuestionId={prevQuestion.id} />
    </>
  );
};

MarketingQuestion.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }).isRequired,
  prevQuestion: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    answerIds: PropTypes.object.isRequired,
  }),
};

MarketingQuestion.defaultProps = {
  prevQuestion: null,
};

export default MarketingQuestion;
